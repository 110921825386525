:root {
  /* Colours */
  --dark10: #121827;
  --dark20: #222222;
  --dark30: #0D1C0B;
  --black: #000000;
  --white: #FFFFFF;
  --green: #99D173;
  --blue: #46A5D6;
  --orange: #C14631;
  --purple: #53389E;
  --red: #961D18;
  --dark-blue: #132B54;
  --pink: #FF95BD;
  /* Font Family */
  --font-mansalva: "Mansalva";
  --font-englebert: "Englebert", sans-serif;
}

.text-stroke-orange {
  color: var(--orange) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-purple {
  color: var(--purple) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-red {
  color: var(--red) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-dark-blue {
  color: var(--dark-blue) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}