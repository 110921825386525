:root {
  /* Colours */
  --dark10: #121827;
  --dark20: #222222;
  --dark30: #0D1C0B;
  --black: #000000;
  --white: #FFFFFF;
  --green: #99D173;
  --blue: #46A5D6;
  --orange: #C14631;
  --purple: #53389E;
  --red: #961D18;
  --dark-blue: #132B54;
  --pink: #FF95BD;
  /* Font Family */
  --font-mansalva: "Mansalva";
  --font-englebert: "Englebert", sans-serif;
}

.text-stroke-orange {
  color: var(--orange) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-purple {
  color: var(--purple) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-red {
  color: var(--red) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-dark-blue {
  color: var(--dark-blue) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.footer {
  background-color: #fff;
  padding-top: 61.5px;
  padding-bottom: 58px;
}
.footer p {
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 37.5px */
  color: #525154;
  margin-top: 89px;
}

.generic-modal .modal-dialog {
  max-width: 688px;
}
.generic-modal .modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 32px;
}
.generic-modal .modal-dialog .modal-header .btn-close {
  font-size: 12px;
  color: #A4A7AE;
}
.generic-modal .modal-dialog .modal-header h3 {
  color: #000;
  font-family: var(--font-englebert);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 0px;
}
.generic-modal .modal-dialog .modal-body p {
  color: #525154;
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.generic-modal .modal-dialog .modal-footer {
  border-top: solid 1px #E9EAEB;
}
.generic-modal .modal-dialog .modal-footer .btn-secondary {
  background-color: var(--white);
  color: #414651;
  border-radius: 8px;
  border: solid 2px #D5D7DA;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 18px;
}
.generic-modal .modal-dialog .modal-footer .btn-secondary:hover {
  opacity: 0.9;
}
.generic-modal .modal-dialog .modal-footer .btn-primary {
  background-color: #7F56D9;
  color: var(--white);
  border-radius: 8px;
  border: solid 1px #D5D7DA;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 18px;
}
.generic-modal .modal-dialog .modal-footer .btn-primary:hover {
  opacity: 0.9;
}