:root {
  /* Colours */
  --dark10: #121827;
  --dark20: #222222;
  --dark30: #0D1C0B;
  --black: #000000;
  --white: #FFFFFF;
  --green: #99D173;
  --blue: #46A5D6;
  --orange: #C14631;
  --purple: #53389E;
  --red: #961D18;
  --dark-blue: #132B54;
  --pink: #FF95BD;
  /* Font Family */
  --font-mansalva: "Mansalva";
  --font-englebert: "Englebert", sans-serif;
}

.text-stroke-orange {
  color: var(--orange) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-purple {
  color: var(--purple) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-red {
  color: var(--red) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.text-stroke-dark-blue {
  color: var(--dark-blue) !important;
  text-shadow: 0px 4.162px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-text-stroke-width: 3.121645689px !important;
}

.wrapper {
  background-image: url(5ffb1796a5d74b2a85b27d733b9cdafa.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -150px;
  width: 100%;
  height: 100%;
  margin: 0px;
  position: relative;
  z-index: 1;
}
.wrapper .banner {
  background-image: url(5ffb1796a5d74b2a85b27d733b9cdafa.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -150px;
  width: 100%;
  height: 1014px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.header-logo {
  margin: 0 auto 21px;
  height: 130px;
}
.header-logo::before {
  content: "";
  background-color: var(--dark10);
  width: 293px;
  height: 260px;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: -130px auto;
}
.header-logo img {
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block;
  padding-top: 18px;
}

.heading-large {
  font-family: var(--font-mansalva);
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 108px */
  letter-spacing: -1.44px;
  color: var(--black);
  text-shadow: 0px 8.18px 0px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 6.1347303391px;
  -webkit-text-stroke-color: var(--white);
  paint-order: stroke fill;
  margin-bottom: 29px;
}
@media screen and (max-width: 767px) {
  .heading-large {
    font-size: 40px;
  }
}

.content-wrapper::before {
  content: "";
  background-image: url(63b174d6b77427e1650c0bcf44ecc0c5.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 133px;
  display: block;
}
.content-wrapper::after {
  content: "";
  background-image: url(b6910d1eee632a7f335044dd3b8cebc2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 117px;
  display: none;
}
.content-wrapper .inner {
  background-color: var(--dark20);
  width: 100%;
  margin-top: -10px;
  margin-bottom: -30px;
}
.content-wrapper .box-two-columns {
  margin-bottom: 51px;
}
.content-wrapper .box-two-columns.full .box-image {
  width: 100%;
}
.content-wrapper .box-two-columns .box-shadow {
  width: 100%;
  height: 307px;
  border-radius: 27.934px;
  box-shadow: 0px 4.656px 0px 0px #FFF;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1199px) {
  .content-wrapper .box-two-columns .box-shadow {
    height: auto;
  }
}
@media screen and (max-width: 479px) {
  .content-wrapper .box-two-columns .box-shadow {
    height: 120px;
  }
}
.content-wrapper .box-two-columns .box-shadow::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}
.content-wrapper .box-two-columns .box-image a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.content-wrapper .box-two-columns .box-image a img {
  width: 100%;
  height: 100%;
}
.content-wrapper .box-two-columns .box-image a:hover {
  opacity: 0.7;
}
.content-wrapper .box-two-columns .box-image .overlays {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.content-wrapper .box-two-columns .box-image .overlays .icon {
  margin-bottom: 11px;
}
@media screen and (max-width: 767px) {
  .content-wrapper .box-two-columns .box-image .overlays .icon svg {
    width: 48px;
    height: 48px;
  }
}
.content-wrapper .box-two-columns .box-image .overlays .content .heading {
  color: var(--white);
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
  letter-spacing: -0.4px;
  margin-bottom: 11px;
}
@media screen and (max-width: 767px) {
  .content-wrapper .box-two-columns .box-image .overlays .content .heading {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.content-wrapper .box-two-columns .box-image .overlays .content .date {
  color: var(--white);
  text-align: center;
  font-family: var(--font-englebert);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}
@media screen and (max-width: 1199px) {
  .content-wrapper .box-two-columns .box-image {
    margin-bottom: 30px;
  }
  .content-wrapper .box-two-columns .box-image a img {
    height: auto;
  }
}
@media screen and (max-width: 1199px) and (max-width: 479px) {
  .content-wrapper .box-two-columns .box-image a img {
    height: 120px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.content-wrapper .box-account {
  margin-bottom: 74px;
}
.content-wrapper .box-account p {
  font-family: var(--font-englebert);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 37.5px */
  color: var(--white);
  margin-bottom: 70px;
  padding: 0px 35px;
}
@media screen and (max-width: 767px) {
  .content-wrapper .box-account p {
    font-size: 20px;
    padding: 0px;
  }
}
.content-wrapper .our-modules h2 {
  font-family: var(--font-mansalva);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 108px */
  letter-spacing: -0.8px;
  color: var(--black);
  text-shadow: 0px 8.18px 0px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 6.1347303391px;
  -webkit-text-stroke-color: var(--white);
  paint-order: stroke fill;
}
@media screen and (max-width: 767px) {
  .content-wrapper .our-modules h2 {
    font-size: 32px;
  }
}
.content-wrapper .our-modules .item {
  margin-bottom: 140px;
}
@media screen and (max-width: 767px) {
  .content-wrapper .our-modules .item {
    margin-bottom: 70px;
  }
}
.content-wrapper .our-modules .item img {
  margin-bottom: 27px;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: 1s all;
}
.content-wrapper .our-modules .item img:hover {
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}
.content-wrapper .our-modules .item h3 {
  font-family: var(--font-mansalva);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 108px */
  letter-spacing: -0.64px;
  text-transform: capitalize;
  color: var(--black);
  text-shadow: 0px 8.18px 0px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 3.121645689px;
  -webkit-text-stroke-color: var(--white);
  paint-order: stroke fill;
}
@media screen and (max-width: 767px) {
  .content-wrapper .our-modules .item h3 {
    font-size: 18px;
  }
}

.contact {
  position: relative;
  background-color: var(--white);
}
.contact .img-floating {
  width: 100%;
  height: 100%;
}
.contact .img-floating::before {
  content: "";
  background-image: url(a600d57cced36a55c0045bb282856a4b.png);
  width: 215px;
  height: 172px;
  position: absolute;
  left: 0;
  bottom: 32px;
  display: block;
}
@media screen and (max-width: 370px) {
  .contact .img-floating::before {
    width: 168px;
    height: 134px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.contact .img-floating::after {
  content: "";
  background-image: url(c4fc13a7e9e897711cc08563da0a19cb.png);
  width: 149px;
  height: 208px;
  position: absolute;
  right: 0;
  bottom: 32px;
  display: block;
}
@media screen and (max-width: 370px) {
  .contact .img-floating::after {
    height: 166px;
    background-size: contain;
    width: 119px;
    background-repeat: no-repeat;
  }
}
.contact::before {
  content: "";
  background-image: url(b82449645f6f99fc4e7cb668b7dc4f7d.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  display: block;
}
.contact::after {
  content: "";
  background-image: url(c53d571e5bdd13f356a1d574322fa2f7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 31px;
  display: block;
  margin-top: 250px;
}
.contact .inner {
  max-width: 1020px;
  margin: 0 auto;
  padding-top: 116px;
}
@media screen and (max-width: 767px) {
  .contact .inner {
    padding-top: 0px;
  }
}
.contact .inner .contact-heading {
  font-family: var(--font-mansalva);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 108px */
  letter-spacing: -0.8px;
  color: var(--black);
  text-shadow: 0px 8.18px 0px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 6.1347303391px;
  -webkit-text-stroke-color: var(--white);
  paint-order: stroke fill;
  color: var(--dark20) !important;
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .contact .inner .contact-heading {
    font-size: 32px;
  }
}
.contact .inner .thankyou-message {
  color: #AB0278;
  text-align: center;
  font-family: var(--font-englebert);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 60px */
  letter-spacing: -0.8px;
  margin-bottom: 100px;
}
.contact .inner .contact-text {
  color: var(--black);
  text-align: center;
  font-family: var(--font-englebert);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 60px */
  letter-spacing: -0.8px;
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .contact .inner .contact-text {
    font-size: 32px;
  }
}
.contact .inner .contact-text .input-wrapper {
  position: relative;
}
.contact .inner .contact-text .input-wrapper.error::after {
  content: "!";
  content: "x";
  display: block;
  font-size: 30px;
  position: absolute;
  right: 0;
  top: -5px;
  color: #930202;
}
.contact .inner .contact-text input {
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(16f5bf3f3358b6c63b1d868480523473.png);
  background-repeat: repeat-x;
  background-position: left 40px;
  color: #AB0278;
  line-height: 150%;
  text-align: center;
  padding: 0px 5px;
  font-family: var(--font-englebert);
}
.contact .inner .contact-text input.error {
  background-image: url(508f9ff47e30b41b549ffe0ee8073e32.png);
}
.contact .inner .contact-text label {
  display: none !important;
}
.contact .inner .g-recaptcha {
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 32px;
}
.contact .inner #response-message {
  color: #930202;
  text-align: center;
  font-family: var(--font-englebert);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.8px;
  margin-bottom: 32px;
}
.contact .inner .btn-submit {
  position: relative;
  overflow: hidden;
  border-radius: 12.8px;
  border: 1.6px solid #FFF;
  background: #AB0278;
  box-shadow: 0px 3.2px 0px 0px rgba(0, 0, 0, 0.15);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 199px;
  height: 64px;
  padding-bottom: 3.2px;
  margin: 0 auto 32px;
}
.contact .inner .btn-submit:hover {
  opacity: 0.9;
}
.contact .inner .btn-submit::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #FF95BD;
  position: absolute;
  border-radius: 12.8px;
}
.contact .inner .btn-submit::before:hover {
  opacity: 0.9;
}
.contact .inner .btn-submit span {
  color: #FFF;
  font-family: var(--font-englebert);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .contact .inner .btn-submit span {
    font-size: 16px;
  }
}
.contact .inner .btn-submit span:hover {
  opacity: 0.9;
}
.contact .inner .artwork {
  text-align: center;
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
  color: var(--black);
  margin-bottom: 64px;
}
.contact .inner .artwork a {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--black);
  text-decoration: none;
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .contact .inner .artwork {
    font-size: 14px;
  }
}
@media screen and (max-width: 479px) {
  .contact .inner .artwork {
    font-size: 9px;
  }
}

.btn-group {
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  width: 100%;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 767px) {
  .btn-group {
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
  }
}

.btn-new {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 300px;
  min-height: 100px;
  padding-bottom: 3.2px;
  border-radius: 20px;
  border: 1.6px solid var(--white);
  background: var(--dark30);
  box-shadow: 0px 3.2px 0px 0px rgba(131, 120, 120, 0.15);
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 1199px) {
  .btn-new {
    min-width: 50%;
  }
}
@media screen and (max-width: 1199px) {
  .btn-new {
    min-height: 50px;
  }
}
.btn-new::before {
  content: "";
  border-radius: 20px;
  background: var(--dark30);
  width: 100%;
  height: 96.4px;
  position: absolute;
}
@media screen and (max-width: 1199px) {
  .btn-new::before {
    min-width: 50%;
  }
}
@media screen and (max-width: 1199px) {
  .btn-new::before {
    height: 48.2px;
  }
}
.btn-new.btn-blue::before {
  content: "";
  background: var(--blue);
}
.btn-new.btn-green::before {
  content: "";
  background: var(--green);
}
.btn-new span {
  position: relative;
  font-family: var(--font-englebert);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 33px */
  letter-spacing: -0.6px;
  text-decoration: none;
  color: var(--white);
}
@media screen and (max-width: 767px) {
  .btn-new span {
    font-size: 16px;
  }
}
.btn-new:hover {
  opacity: 0.9;
}

.form-contact {
  padding-bottom: 60px;
}
.form-contact h2 {
  font-family: var(--font-mansalva);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 108px */
  letter-spacing: -0.8px;
  color: var(--black);
  text-shadow: 0px 8.18px 0px rgba(0, 0, 0, 0.15);
  -webkit-text-stroke-width: 6.1347303391px;
  -webkit-text-stroke-color: var(--white);
  paint-order: stroke fill;
}
@media screen and (max-width: 767px) {
  .form-contact h2 {
    font-size: 32px;
  }
}
.form-contact form {
  max-width: 470px;
  margin-left: auto;
  font-family: var(--font-englebert);
  margin-top: 40px;
  margin-bottom: 30px;
}
.form-contact form input[name=search] {
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--white);
  box-shadow: none;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  border-radius: 0px;
  padding: 0px;
  margin-right: 15px;
}
.form-contact form input::-webkit-input-placeholder {
  color: var(--white);
  opacity: 0.5;
}
.form-contact form input::-moz-placeholder {
  color: var(--white);
  opacity: 0.5;
}
.form-contact form input:-ms-input-placeholder {
  color: var(--white);
  opacity: 0.5;
}
.form-contact form input::-ms-input-placeholder {
  color: var(--white);
  opacity: 0.5;
}
.form-contact form input::placeholder {
  color: var(--white);
  opacity: 0.5;
}
.form-contact form button[type=submit] {
  margin-top: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 12.8px;
  border: 1.6px solid #FFF;
  background: #AB0278;
  box-shadow: 0px 3.2px 0px 0px rgba(0, 0, 0, 0.15);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 120px;
  height: 40px;
  margin: 0 auto 0px;
}
.form-contact form button[type=submit]:hover {
  opacity: 0.9;
}
.form-contact form button[type=submit]::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #FF95BD;
  position: absolute;
  border-radius: 12.8px;
}
.form-contact form button[type=submit]::before:hover {
  opacity: 0.9;
}
.form-contact form button[type=submit] span {
  color: #FFF;
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  letter-spacing: -0.48px;
  position: relative;
}
.form-contact form button[type=submit] span:hover {
  opacity: 0.9;
}
.form-contact table {
  border-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}
.form-contact table thead th, .form-contact table tbody td {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-englebert);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.5);
}
.form-contact table thead th a, .form-contact table tbody td a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.form-contact table thead th a:hover, .form-contact table tbody td a:hover {
  color: var(--white);
}
.form-contact .pagination li.disabled a {
  background: var(--dark10);
  border-color: var(--dark10);
  color: rgba(255, 255, 255, 0.5);
}
.form-contact .pagination li a {
  background: var(--blue);
  border-color: var(--blue);
  font-family: var(--font-englebert);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--white);
}
.form-contact .pagination li a:hover {
  opacity: 0.8;
}